<template>
  <div class="seeker_header fixed-top w-100">
  <b-navbar toggleable="lg" class="header-nav container" v-click-outside="hideCollapsableMenu">
    <router-link to="/" class="nav-logo">
      <img title="hireclap-logo" src="/hireclap-logo-small.webp" alt="hireclap| hire from video profiles" class="brand-logo">
    </router-link>
    <!-- https://s3.ap-south-1.amazonaws.com/www.hireclap.com/Assets/images/banner-img.svg -->
    <router-link
      :to="{ name: 'SeekerReg', query: $route.query }"
      v-if="!user"
      class="nav-item m-signin m-show">
      <span class="nav-link">Register</span>
    </router-link>
     
    <b-navbar-toggle target="nav-collapse" v-if="!user"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav class="nav-rend justify-content-end">
      <!-- Right aligned nav items -->
      <b-navbar-nav>
        <b-nav-item href="/institutes" target="_blank" v-if="!user" class="grayTXT cpr-0">Institute <span class="nav-seperator mx-0">/</span></b-nav-item><b-nav-item href="/colleges/register" target="_blank" v-if="!user" class="grayTXT">College <span class="nav-seperator">|</span></b-nav-item>
        <b-nav-item href="/recruit" target="_blank" v-if="!user" class="grayTXT">For Employers</b-nav-item>
        <b-nav-item href="/recruit/post-job" target="_blank" v-if="!user" class="grayTXT">Post free job <span class="nav-seperator">  | </span></b-nav-item>
        <router-link
          :to="{ name: 'JobList' }"
          v-if="!user"
          tag="li"
          class="nav-item mr-2">
          <a class="nav-link blueTXT" target="_blank">Jobs</a>
        </router-link>
        <router-link
          :to="{ name: 'JobseekerSign', query: $route.query }"
          tag="li"
          v-if="!user"
          class="nav-item blue-head btn btn-outline-primary">
          <a class="nav-link" target="_blank">Login</a>
        </router-link>
        <router-link
          :to="{ name: 'SeekerReg', query: $route.query }"
          tag="li"
          v-if="!user"
          class="nav-item blue-head btn btn-primary">
          <a class="nav-link" target="_blank">Register</a>
        </router-link>
      </b-navbar-nav>
    </b-collapse>
    <div class="mr-2 form-group has-search searchButton" v-if="user">
      <div style="display:flex" @click="getJobList" class="btn search-btn btn-outline-primary">
        <!-- <span class="form-control-feedback material-icons">search</span> -->
        <!-- <span class="form-control-feedback"><img :src="$S3_url+'/Assets/images/search-solid.svg'" width="10px" height="15px;"></span> -->
        <div class="srchTxt">Search job</div>
      </div>
    </div>
    <div class="headerThumbox" v-if="user">
      <div style="display:flex">
        <div class="header-thumb" @click.prevent="openModal('visume')" v-if="!user.visume_path">
          <div class="thumb-wrapper">
            <span class="plusIcon">+</span>
          </div>
        </div>
        <div class="header-image-thumb" v-if="user.visume_path">
          <!-- <div class="thumb-wrapper">
            <img :src="user.personal_details.profile_pic" alt="profile-image" width="42px" height="42px" style="border-radius:50%;">
            <span class="plusIcon">+</span>
          </div> -->
          <div class="thumb-wrapper" style="padding:0;">
            <!-- <span class="play-button"></span> -->
            <video :key="user.visume_path" width="42" height="42" class="" preload="metadata" @click.prevent="getProfile"> 
              <source :src="$S3_bucket_url+user.visume_path" type="video/mp4" id="visume_thumbnail"/>
            </video>
          </div>
        </div>
        <div  class="hdrAddVsum" @click="showDropDown=!showDropDown" v-if="user.name">{{user.name.substring(0,10)}} <span  v-if="!user.visume_path"><br>Add visume</span></div>
        
        <!-- <span @click="showDropDown=!showDropDown" class="material-icons">arrow_drop_down</span> -->
        <span @click="showDropDown=!showDropDown" style="margin-left:5px;"><img :src="$S3_url+'/Assets/images/caret-down-solid.svg'" width="10" height="15"></span>
      </div>
      <div v-if="showDropDown" class="dropdown">
        <div class="dropdown-content">
          <div @click.prevent="getProfile">My profile</div>
          <div v-on:click.prevent="showConfirmation('logout_confirmation')" class="">Logout</div>
        </div>
      </div>
    </div>
  </b-navbar>
  
  <!-- <b-alert variant="danger" class="text-center" style="width:79%;left:133px;" :show="user&&(!user.visume_path||!user.resume_path)" v-if="user&&(!user.visume_path||!user.resume_path)"  dismissible ><P class="mb-0 text-left" style="font-size:13px;opacity:0.7"><small><strong>Complete your profile: </strong><span class="ml-2 upload" v-if="!user.visume_path" v-on:click.prevent="upload('visume')">Upload visume</span><span class="ml-5 upload" v-if="!user.resume_path" v-on:click="upload('resume')">Upload resume </span></small></P></b-alert> -->
  <Modals :open="modals.open"/>
  <b-modal ref="upload-resume" title="Upload Resume" hide-footer>
    <div class="container">
      <v-form ref="form" lazy-validation v-model="valid">
        <v-file-input  v-model="resume" accept=".doc,.docx,.rtf,.pdf" placeholder="Post resume" :append-icon="$icons.file" prepend-icon="" :rules="[v => !!v || 'Upload valid resume']"  show-size outlined></v-file-input>
      </v-form>
    </div>
    <template>
      <div class="float-right">
        <b-button class="mr-2" size="sm" v-on:click.prevent="closeModal('upload-resume')">Cancel</b-button>
        <b-button size="sm" variant="success" v-on:click.prevent="uploadResume()" v-if="!loading">Save</b-button>
        <b-button size="sm" variant="success" v-if="loading">...</b-button>
      </div>
    </template>
  </b-modal>
  <b-modal ref="logout_confirmation" title="" centered hide-header hide-footer no-close-on-backdrop>
    <div>
          <div class="header mb-3"><strong>Log out?</strong></div>
          <div class="body"><p>Are you sure you want to log out?</p></div>
          <div class="logout-footer">
            <div class="row text-right">
              <div class="col-12">
                <div class="btn text-muted" role="button" v-on:click.prevent="logout()" v-if="!loading">Yes</div>
                <div class=" blue-head btn btn-primary" role="button" v-on:click.prevent="cancelConfirmation('logout_confirmation')" v-if="!loading">No</div>
              </div>
              <div class="col-12" v-if="loading">Logging out...</div>
            </div>
          </div>
    </div>
  </b-modal>
  <!-- <VisumeModal :visume="user.visume_path" :open="visumeModal.open" v-if="user"/> -->
</div>
</template>

<script>
// const VisumeModal = () => import(/* webpackPrefetch: true */ "@/components/VisumeModal");
const Modals = () => import(/* webpackPrefetch: true */ "@/components/Modals");
import {eventBus} from '../main';
import AwsUpload from "../../utils/s3upload"

import Actions from '../libraries/apiActions.js';
import '../assets/hire.css';
export default {
  name: 'Header',
  components: {
    Modals,
    // VisumeModal
  },
  // props: ['user'],
  data: function(){
    return {
      currentRoute: null,
      action: new Actions(),
      user:null,
      // marquees: [],
      isCollapseOpen:null,
      modals:{ 
        open:'exit',
      },
      visumeModal:{
        open:'exit',
      },
      showDropDown:false,
      filter:false,
      selectedFile:null,
      uploading:false,
      resume_path:null,
      resume: null,
      update:{
        resume:null
      },
      valid:true,    
      loading: false,
    }
  },
  beforeMount(){
    this.currentRoute = window.location.href.replace(process.env.VUE_APP_URL_WS_HTTP, '');
    let data = this.$cookies.get("hire-seeker");
    this.user='';
    if(data){
      this.user = data;
    }else{
      this.user = '';
    }

    if(this.$cookies.get("seeker")){
      this.$cookies.remove("seeker");
    }
  },
  methods:{
      openModal : function(type= ''){
        if (type == "visume" && this.user) {
            eventBus.$emit('open','visume',this.user.visume_path,0,this.visumeModal.open)
          // this.visumeModal.open = 'exit';
          // this.visumeModal.open = type;
        }
        this.modals.open = 'exit';
        this.modals.open = type;
      },
      showConfirmation(type){
        this.$refs[type].show();
      },
      cancelConfirmation(type){
        this.$refs[type].hide();
      },
      hideCollapsableMenu(){
        if(this.isCollapseOpen) {
          this.$root.$emit('bv::toggle::collapse', 'nav-collapse')
        }
      },
      getJobList: function(e) {
        e.preventDefault();
        if(this.$route.path != "/jobs")
           window.open('/jobs','_blank');
          // this.$router.push("/jobs");
        this.filter=true;
      },
      getProfile: function(){
        if(this.$route.path != '/myprofile'){
          this.$router.push("/myprofile");
        }
        
        this.showDropDown = false;
      },
      upload(data){
        if(data == 'visume'){
          this.openModal('visume');
        }else if(data == 'resume'){
          this.$refs['upload-resume'].show();
        }
      },
      async uploadResume(){
        let token = this.$cookies.get("hire-seeker").token;
        this.valid = this.$refs.form.validate();
        if(this.valid == true){
          this.loading = true;
          const file = this.resume;
          const awsUploader = new AwsUpload();
            try {
              var extension = this.resume.name.split('.')[this.resume.name.split('.').length-1]
              var d = new Date();
              var month = d.getMonth() + 1;
              var day = d.getDate();
              var current_date =d.getFullYear() +"/" +(month < 10 ? "0" : "") +month +"/" +(day < 10 ? "0" : "") +day;
              var timestamp = Math.floor(Date.now() / 1000);
              var filename = 'resume/'+ current_date+"/"+this.user.name + timestamp + "."+extension;
              const response = await awsUploader.uploadToS3(file, filename);
              this.update.resume = response.url;
              this.action.updateResume(this.update.resume,token).then(data =>{
                if(data.message == "Resume Updated"){
                  this.loading = false;
                  this.resume = false;
                  this.$refs['upload-resume'].hide();
                  let cookie = this.$cookies.get('hire-seeker');
                  cookie.alert = (cookie.visume_path)?'no':'yes';
                  let updated_cookie = {token:cookie.token,name:cookie.name,visume_path:cookie.visume_path,resume_path:this.update.resume,alert:cookie.alert};
                  this.$cookies.remove("hire-seeker");
                  this.$cookies.set("hire-seeker",updated_cookie);
                }else{
                  this.loading = false;
                }
              }).catch(err =>{
                this.loading = false;
                console.log(err);
              })
            } catch (error) {
              console.error('Failed to upload file to S3:', error);
              // Handle error
            }
        }
      },
      getMarqueeText: function(){
        let marquee = [];
        if(this.updates){
          console.log(this.updates[0])
          this.updates[0].forEach(u => {
            let enabled_pages = u.enabled_pages.replace(/ /g,'').split(',')
            console.log(enabled_pages);
            console.log(this.currentRoute);
            if(enabled_pages.includes(this.currentRoute)) {
              marquee.push(u.title);
            }
            enabled_pages.forEach(p => {
              if(p.match(/[\w\d\s_./-]*[/][.]$/)){
                let p_to_match = p.substring(0, p.length - 1)
                if(this.currentRoute.includes(p_to_match) && !marquee.includes(u.title)){
                  marquee.push(u.title);
                }
              }
            })
          })
        }
        return marquee.join('');
      },
      logout : function(){
        this.loading = true;
        let data = this.$cookies.get("hire-seeker");
        // api.get({Authorization:'Bearer '+data}).
        document.getElementById('nav-collapse').classList.remove('show');
        this.action.seekerLogout(data.token).then((res) => {
          this.loading = false;
          if(res.status == '1'){
            this.$cookies.remove("hire-seeker");
            this.showDropDown = false;
            this.user = null;
            this.$refs['logout_confirmation'].hide();
            if(this.$route.path != '/'){
              this.$router.push("/");
            }
          }
        }).catch(err => {
          this.loading=false;
          console.log(err);
          location.reload();
        })
      },
      postFreeJob(){
        // if(this.$cookies.get('hire-employer')){
          window.open('/recruit/post-job','_blank');
        // }else{
        //   window.open('/recruit/login','_blank');
        // }
        // location.reload();
      }
  },
  watch:{
    $route(){
      this.user = this.$cookies.get('hire-seeker')?this.$cookies.get("hire-seeker"):null;
    }
  }
}
</script>

<style scoped>
.upload:hover,.upload:active{
  text-decoration: underline;
  cursor: pointer;
}
.cpr-0 a{
  padding-right: 0 !important;
}
</style>
